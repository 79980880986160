import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { candlestick } from "../../api/charts/candlestick";
import { toast } from "react-toastify";
import styles from "./candlesticksecs.module.css";

const CandlestickSecs = ({ chartData }) => {
    const chartSecondsContainerRef = useRef();
    const chartSeconds = useRef();

    useEffect(() => {
        if (!chartData || !chartData.data) {
            return;
        }

        if (chartSeconds.current) {
            chartSeconds.current.remove();
        }

        chartSeconds.current = createChart(chartSecondsContainerRef.current, {
            width: chartSecondsContainerRef.current.clientWidth,
            height: chartSecondsContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: true,
            },
        });

        const volumeSeries = chartSeconds.current.addHistogramSeries({
            color: "#182233",
            lineWidth: 2,
            priceFormat: {
                type: "volume",
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
            priceScaleId: "volume",
        });

        volumeSeries.setData(
            chartData.data.bars.map((bar) => ({
                time: bar.time,
                value: bar.volume,
            }))
        );

        const series = chartSeconds.current.addCandlestickSeries({
            upColor: "#26a69a",
            downColor: "#ef5350",
            borderVisible: false,
            wickUpColor: "#26a69a",
            wickDownColor: "#ef5350",
        });

        series.setData(chartData.data.bars);

        const barData = chartData.data.bars;
        if (barData.length > 0) {
            const from = barData[0].time;
            const to = barData[Math.min(100, barData.length - 1)].time; // Adjust the range length as needed
            chartSeconds.current.timeScale().setVisibleRange({ from, to });
        }

    }, [chartData]);

    return (
        <div>
            <div ref={chartSecondsContainerRef} className={styles["chart-container"]} />
        </div>
    )
};

export default CandlestickSecs;
