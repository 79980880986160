import { client } from "../../axios/axios";

export const orders = (date, isPaper, symbol, page, pageSize, columnName, order) => {
    let url = "api/orders";

    const queryParams = new URLSearchParams();
    queryParams.append("date", date);
    queryParams.append("is_paper", isPaper);
    queryParams.append("asset_id", symbol);
    queryParams.append("page", page);
    queryParams.append("page_size", pageSize);

    if (columnName && order) {
        queryParams.append("column_name", columnName);
        queryParams.append("order", order);
    }

    url += "?" + queryParams.toString();
    return client.get(url);
};

export const orderById = (id) => {
    let url = "api/orders/" + id;
    return client.get(url);
};
