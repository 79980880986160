import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CustomPaginationTable from "../../components/paginationTable/CustomPaginationTable";
import { portfolios as portfoliosApiCall } from "../../api/portfolios/portfolios";
import CustomTable from "../../components/customTable/customTable";
import styles from "./allCharts.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from "../../utils/dateFormat";

function toFixed(val, dec) {
    if (!val) {
        return val;
    }

    return val.toFixed(dec);
}

export const portfoliosColumns = [
    {
        name: "start_date",
        display: "Start date",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "max_pos_pct",
        display: "Max Pos PCT",
        type: "float",
    },
    {
        name: "start_value",
        display: "Start value",
        type: "float",
    },
    {
        name: "end_value",
        display: "End value",
        type: "float",
    },
    {
        name: "max_pct_doll_voll",
        display: "Max Pct Doll Voll",
        type: "float",
    },
    {
        name: "min_last_10_pct_ret",
        display: "Last 10 returns",
        type: "float",
    },
    {
        name: "min_last_50_pct_ret",
        display: "Last 50 returns",
        type: "float",
    },
    {
        name: "min_last_100_pct_ret",
        display: "Last 100 returns",
        type: "float",
    },
    {
        name: "min_last_500_pct_ret",
        display: "Last 500 returns",
        type: "float",
    },
    {
        name: "min_all_return_pct",
        display: "Min All Return Pct",
        type: "float",
    },
];

function Portfolios() {
    const [portfoliosData, setPortfoliosData] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    const [barDuration, setBarDuration] = useState("1-mins");
    const barDurations = ["1-mins", "3-mins", "5-mins", "10-mins", "15-mins", "30-mins", "60-mins", "1-days"];

    const assetRef = useRef("");
    const backtestIdRef = useRef("");

    const navigate = useNavigate();
    const fetchChartData = useCallback(
        async (portfolio) => {
            navigate(`/portfolios/${portfolio.id}`);
        },
        []
    );

    const fetchPortfolioData = useCallback(async (page, pageSize, columnName, order) => {
        let response = await portfoliosApiCall(page, pageSize, columnName, order);
        if (!response) {
            toast.error("no backtests");
            return;
        }

        if (response.status !== 200) {
            toast.error(response?.data?.error);
            return;
        }

        setPortfoliosData(response.data);
    }, []);

    return (
        <div style={{ width: "100%", height: "95vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2>Backtests</h2>
            <div style={{ flex: 1, overflow: "hidden" }}>
                <CustomPaginationTable
                    data={portfoliosData?.data}
                    total={portfoliosData?.total}
                    fetchDataRequest={fetchPortfolioData}
                    rowClickRequest={fetchChartData}
                    columns={portfoliosColumns}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
}

export default Portfolios;


