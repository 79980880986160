
export function formatDateTime(dateTimeStr) {
    if (!dateTimeStr) {
        return dateTimeStr
    }

    // Create a Date object from the ISO string
    let dateObj = new Date(dateTimeStr);
    if (isNaN(dateObj.getTime())) {
        return null;
    }
    
    let options = {
        timeZone: 'America/New_York',
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    let formatter = new Intl.DateTimeFormat('en-US', options);
    let parts = formatter.formatToParts(dateObj);
    let formattedDateTime = parts.reduce((acc, part) => {
        switch (part.type) {
            case 'day':
                return acc + part.value + '/';
            case 'month':
                return part.value + '/' + acc;
            case 'year':
                return acc + part.value + ' ';
            case 'hour':
            case 'minute':
            case 'second':
                return acc + part.value + ':';
            default:
                return acc;
        }
    }, '').slice(0, -1);
    return `${formattedDateTime} NYC`;
}
