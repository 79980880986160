import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import styles from "./portfolio.module.css";

const PortfolioChart = ({ chartData }) => {
    const chartContainerRef = useRef();
    const chart = useRef();

    useEffect(() => {
        if (!chartData || !chartData.data) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: false,
            },
        });

        const lineSeries = chart.current.addLineSeries({ color: "#2962FF" });

        lineSeries.setData(chartData.data.portfolios);

        const startTime = chartData.data.portfolios[0].time;
        const endTime = chartData.data.portfolios[chartData.data.portfolios.length - 1].time;
        const horizontalLineSeries = chart.current.addLineSeries({
            color: "#FF0000",
            lineWidth: 1,
        });

        horizontalLineSeries.setData([
            { time: startTime, value:  chartData.data.portfolios[0].value },
            { time: endTime, value: chartData.data.portfolios[0].value },
        ]);

        chart.current.timeScale().fitContent();

    }, [chartData]);

    return <div ref={chartContainerRef} className={styles["chart-container"]} />;
};

export default PortfolioChart;
