import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import styles from "./movingAverageHistograms.module.css";

const MovingAveragesHistogramsChart = ({ chartData }) => {
    const chartContainerRef = useRef();
    const chart = useRef();

    useEffect(() => {
        if (!chartData || !chartData.data) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: true,
            },
        });

        const movingAverage10 = chart.current.addLineSeries({
            color: "#2962FF",
            priceFormat: {
                type: 'price',
                precision: 4,
                minMove: 0.0001,
            },
        });

        movingAverage10.setData(chartData.data.moving_average_10);

        const movingAverage50 = chart.current.addLineSeries({ 
            color: "red",
            priceFormat: {
                type: 'price',
                precision: 4,
                minMove: 0.0001,
            },
        });

        movingAverage50.setData(chartData.data.moving_average_50);

        const movingAverage200 = chart.current.addLineSeries({
            color: "#00FF00",
            priceFormat: {
                type: 'price',
                precision: 4,
                minMove: 0.0001,
            },
        });

        movingAverage200.setData(chartData.data.moving_average_200);

        movingAverage10.createPriceLine({
            price: 0,
            color: "white",
            lineWidth: 1,
            lineStyle: 1, // 0 for solid, 1 for dotted, 2 for dashed
            axisLabelVisible: false,
            title: '',
        });

        chart.current.timeScale().fitContent();

    }, [chartData]);

    return <div >
        <div ref={chartContainerRef} className={styles["chart-container"]} />
        <div style={{ color: "white", backgroundColor: "#253248", padding: "10px" }} className={styles["chart-container-add"]}>
            <div style={{ color: "#2962FF" }}>10-Day Moving Average</div>
            <div style={{ color: "red" }}>50-Day Moving Average</div>
            <div style={{ color: "#00FF00" }}>200-Day Moving Average</div>
        </div>
    </div>;
};

export default MovingAveragesHistogramsChart;
