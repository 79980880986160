import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import styles from "./customTable.module.css";
import { useEffect } from "react";
import { formatDateTime } from "../../utils/dateFormat";

export default function CustomTable({ rows }) {
    if (!rows) {
        return;
    }

    const columnHeaders = rows.length > 0 ? Object.keys(rows[0]) : [];
    const formatValue = (value) => {

        if (typeof value === "number") {
            if (Number.isInteger(value)) {
                return value.toString();
            } else {
                return value.toFixed(4);
            }
        }else{
            // if string is date show in special format
            let val = formatDateTime(value)
            if (val != null){
                return val;
            }
        }
        return value;
    };

    return (
        <div className={styles.container}>
            <div>
                <TableContainer component={Paper} >
                    <Table size="small" aria-label="a dense table">
                        <TableBody className={styles.tableBody}>
                            {Array.from({ length: Math.ceil(columnHeaders.length / 3) }).map((_, rowIndex) => (
                                <TableRow key={rowIndex} >
                                    {columnHeaders.slice(rowIndex * 3, rowIndex * 3 + 3).map((column) => (
                                        <React.Fragment key={column}>
                                            <TableCell align="left" className={styles.smallRowHeight}>
                                                <b className={styles.smallFont}>{column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, " ")}</b>
                                            </TableCell>
                                            {rows.map((row, index) => (
                                                <TableCell key={index} align="left" className={styles.smallRowHeight}>
                                                    <p className={styles.smallFont}>{formatValue(row[column])} </p>
                                                </TableCell>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    {/* If less than 3 columns, fill the remaining cells with empty cells */}
                                    {Array.from({ length: 3 - columnHeaders.slice(rowIndex * 3, rowIndex * 3 + 3).length }).map((_, idx) => (
                                        <React.Fragment key={idx}>
                                            <TableCell className={styles.smallRowHeight} />
                                            <TableCell className={styles.smallRowHeight} />
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
