import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { trades } from '../../api/charts/trades';
import { toast } from 'react-toastify';
import TradesCandlestick from '../../components/candlestick/TradesCandlestick';
import { Select, MenuItem, Box, CircularProgress, Button } from '@mui/material';
import styles from './backtest.module.css';
import { backtestById } from "../../api/backtests/backtests";
import CustomTable from "../../components/customTable/customTable";
import { candlestick } from '../../api/charts/candlestick';
import { movingAverage } from '../../api/charts/movingAverage';
import { histogram } from '../../api/charts/histogram';

import { portfolio } from '../../api/charts/portfolio';
import Candlestick from '../../components/candlestick/Candlestick';
import MovingAveragesHistogramsChart from '../../components/movingAverageHistogram/MovingAveragesHistogramsChart';
import PortfolioChart from '../../components/portfolio/PortfolioChart';
import HistogramChart from '../../components/histogram/HistogramChart';


function BackTest() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selectedBacktest, setSelectedBacktest] = useState();
    const [tradesChartData, setTradesChartData] = useState();
    const [tradesLoading, setTradesLoading] = useState(false);
    const [content, setContent] = useState("histograms");

    const [candlestickData, setCandlestickData] = useState();
    const [candlestickLoading, setCandlestickLoading] = useState(false);
    const [movingAverageChartData, setMovingAverageChartData] = useState();
    const [loadingMovingAverage, setLoadingMovingAverage] = useState(false);
    const [portfolioChartData, setPortfolioChartData] = useState();
    const [loadingPortfolio, setLoadingPortfolio] = useState(false);

    const [pctReturnData, setPctReturnData] = useState();
    const [loadingPctReturn, setLoadingPctReturn] = useState(false);

    const [numberOfInstances, setNumberOfInstances] = useState();
    const [loadingNumberOfInstances, setLoadingNumberOfInstances] = useState(false);

    const [barDuration, setBarDuration] = useState("1-mins");
    const barDurations = ["1-mins", "3-mins", "5-mins", "10-mins", "15-mins", "30-mins", "60-mins", "1-days"];
    const assetRef = useRef("");
    const backtestIdRef = useRef("");

    const GetHistograms = useCallback(async (backtest_id) => {
        setLoadingPctReturn(true);
        setLoadingNumberOfInstances(true);

        let histogramResponse = await histogram({
            backtest_id,
        });
        setLoadingPctReturn(false);
        setLoadingNumberOfInstances(false);

        if (!histogramResponse || !histogramResponse.data) {
            toast.error("GetHistograms timeout");
            setCandlestickData(null);
            return;
        }

        if (histogramResponse.status !== 200) {
            toast.error(histogramResponse?.data?.error);
            setCandlestickData(null);
            return;
        }


        setPctReturnData(histogramResponse.data.pct_returns)
        setNumberOfInstances(histogramResponse.data.number_of_instances)
    }, []);

    const GetCandlestickChart = useCallback(async (asset_id, predicted_bar_duration, backtest_id) => {
        if (!asset_id) {
            toast.error("GetCandlestickChart asset_id is required but none was found.");
            return;
        }
        setCandlestickLoading(true);
        setCandlestickData(null);

        let candlestickResponse = await candlestick({
            asset_id: asset_id,
            bar_duration: predicted_bar_duration,
            backtest_id,
        });
        setCandlestickLoading(false);
        if (!candlestickResponse || !candlestickResponse.data) {
            toast.error("GetCandlestickChart timeout");
            setCandlestickData(null);
            return;
        }

        if (candlestickResponse.status !== 200) {
            toast.error(candlestickResponse?.data?.error);
            setCandlestickData(null);
            return;
        }

        if (candlestickResponse.data.bars.length === 0) {
            toast.error("GetCandlestickChart no bars for asset_id " + asset_id);
            setCandlestickData(null);
            return;
        }

        setBarDuration(predicted_bar_duration);
        assetRef.current = asset_id;
        backtestIdRef.current = backtest_id;

        candlestickResponse.asset_id = asset_id;
        candlestickResponse.backtest_id = backtest_id;
        setCandlestickData(candlestickResponse);
    }, []);

    const GetMovingAverageChart = useCallback(async (backtest_id) => {
        setLoadingMovingAverage(true);
        let movingAverageResponse = await movingAverage({
            backtest_id,
        });
        setLoadingMovingAverage(false);

        if (!movingAverageResponse || !movingAverageResponse.data) {
            toast.error("timeout");
            setMovingAverageChartData(null);
            return;
        }

        if (movingAverageResponse.status !== 200) {
            toast.error(movingAverageResponse?.data?.error);
            setMovingAverageChartData(null);
            return;
        }

        if (
            movingAverageResponse.data.moving_average_10.length === 0 &&
            movingAverageResponse.data.moving_average_50.length === 0 &&
            movingAverageResponse.data.moving_average_200.length === 0
        ) {
            toast.error("not enough data to calculate moving average");
            setMovingAverageChartData(null);
            return;
        }

        setMovingAverageChartData(movingAverageResponse);
    }, []);

    const GetPortfolioChart = useCallback(async (backtest_id) => {
        setLoadingPortfolio(true);
        let portfolioResponse = await portfolio({
            backtest_id,
        });
        setLoadingPortfolio(false);

        if (!portfolioResponse || !portfolioResponse.data) {
            toast.error("timeout");
            setPortfolioChartData(null);
            return;
        }

        if (portfolioResponse.status !== 200) {
            toast.error(portfolioResponse?.data?.error);
            setPortfolioChartData(null);
            return;
        }

        if (portfolioResponse.data.portfolios.length === 0) {
            toast.error("portfolioResponse.data.portfolios.length === 0");
            setPortfolioChartData(null);
            return;
        }

        setPortfolioChartData(portfolioResponse);
    }, []);

    const handleBarDurChange = useCallback(
        async (event, newDuration) => {
            let res = await backtestById(+id)
            if (!res || res.status !== 200) {
                toast.error(res?.data?.error);
                return;
            }

            const asset_id = res.data.asset_id;
            const predicted_bar_duration = res.data.predicted_bar_duration;
            setBarDuration(predicted_bar_duration);
            setSelectedBacktest(res.data)
            GetCandlestickChart(asset_id, barDuration, +id);
        },
        [GetCandlestickChart]
    );

    const GetTradesChartData = useCallback(async (backtest_id) => {
        setTradesLoading(true);
        setTradesChartData(null);

        let tradesResponse = await trades({
            backtest_id: backtest_id,
        });
        setTradesLoading(false);
        if (!tradesResponse || !tradesResponse.data) {
            toast.error("timeout");
            setTradesChartData(null);
            return;
        }

        if (tradesResponse.status !== 200) {
            toast.error(tradesResponse?.data?.error);
            setTradesChartData(null);
            return;
        }

        if (tradesResponse.data.trades.length === 0) {
            toast.error("no trades for that backtest");
            setTradesChartData(null);
            return;
        }

        setTradesChartData(tradesResponse);
    }, []);

    const fetchTradesBacktestData = async (id) => {
        let res = await backtestById(id)
        if (!res || res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }

        setSelectedBacktest(res.data)
        GetTradesChartData(id);
    };

    const fetchCandlestickBacktestData = async (id) => {
        let res = await backtestById(id)
        if (!res || res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }

        setSelectedBacktest(res.data)
        const asset_id = res.data.asset_id;
        // const predicted_bar_duration = res.data.predicted_bar_duration;
        GetCandlestickChart(asset_id, barDuration, id);
    };


    const fetchHistogramBacktestData = async (id) => {
        let res = await backtestById(id)
        if (!res || res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }

        setSelectedBacktest(res.data)
        const asset_id = res.data.asset_id;
        // const predicted_bar_duration = res.data.predicted_bar_duration;
        GetMovingAverageChart(id);
        GetPortfolioChart(id);
        GetHistograms(id);
    };

    useEffect(() => {
        if (id) {
            if (content === "histograms") {
                fetchHistogramBacktestData(+id)
            } else if (content === "trades") {
                fetchTradesBacktestData(+id);
            }
            else if (content === "tradesOnChart") {
                fetchCandlestickBacktestData(+id);
                fetchTradesBacktestData(+id);
            }
        }
    }, [id, content]);

    return (
        <div className={styles["backtestDetail-container"]}>
            <div className={styles["backtestDetail-header"]}>
                <Button className={styles["backtest-back-button"]} onClick={() => navigate('/backtests')}>{'< All Backtests'}</Button>
                {selectedBacktest && <CustomTable rows={[selectedBacktest]} />}
                <div className={styles["button-group"]}>
                    <Button
                        className={content === "histograms" ? styles["active"] : ""}
                        onClick={() => setContent("histograms")}
                    >
                        Histograms
                    </Button>
                    <Button
                        className={content === "trades" ? styles["active"] : ""}
                        onClick={() => setContent("trades")}
                    >
                        Trades
                    </Button>
                    <Button
                        className={content === "tradesOnChart" ? styles["active"] : ""}
                        onClick={() => setContent("tradesOnChart")}
                    >
                        Trades on Chart
                    </Button>
                </div>
            </div>


            {content === 'trades' && (
                <div className={styles["backtestDetail-content"]}>
                    {tradesLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {tradesChartData && (
                        tradesChartData.data.trades.map((trade, index) => (
                            <TradesCandlestick key={index} trade={trade} tradeNumber={index + 1} />
                        ))
                    )}
                </div>
            )}

            {content === 'histograms' && (
                <div className={styles["backtestDetail-content"]}>
                    <div className={styles["backtestDetail-candlestick-container"]}>
                        <Select
                            labelId="bardur-select-label"
                            id="bardur-select"
                            value={barDuration}
                            onChange={handleBarDurChange}
                            className={styles["backtestDetail-barduration-text-field"]}
                        >
                            {barDurations.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    {loadingPctReturn && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {pctReturnData && (
                        <>
                            <h3 className={styles["backtestDetail-chart-name"]}>PCT return histogram</h3>
                            <HistogramChart chartData={pctReturnData}></HistogramChart>
                        </>
                    )}

                    {loadingNumberOfInstances && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {numberOfInstances && (
                        <>
                            <h3 className={styles["backtestDetail-chart-name"]}>Number Of Instances Histogram</h3>
                            <HistogramChart chartData={numberOfInstances}></HistogramChart>
                        </>
                    )}
                    {loadingMovingAverage && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {movingAverageChartData && (
                        <>
                            <h3 className={styles["backtestDetail-chart-name"]}>Moving Averages</h3>
                            <MovingAveragesHistogramsChart chartData={movingAverageChartData}></MovingAveragesHistogramsChart>
                        </>
                    )}

                    {loadingPortfolio && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {portfolioChartData && (
                        <>
                            <h3 className={styles["backtestDetail-chart-name"]}>Portfolio Chart</h3>
                            <PortfolioChart chartData={portfolioChartData}></PortfolioChart>
                        </>
                    )}


                </div>
            )}

            {content === 'tradesOnChart' && (
                <div className={styles["backtestDetail-content"]}>
                    <div className={styles["backtestDetail-candlestick-container"]}>
                        <Select
                            labelId="bardur-select-label"
                            id="bardur-select"
                            value={barDuration}
                            onChange={handleBarDurChange}
                            className={styles["backtestDetail-barduration-text-field"]}
                        >
                            {barDurations.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    {candlestickLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {candlestickData && (
                        <Candlestick chartData={candlestickData}></Candlestick>
                    )}
                </div>
            )}
        </div>
    );
}

export default BackTest;
