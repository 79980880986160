import React, { useCallback, useRef, useEffect } from "react";
import { orders } from "../../api/orders/orders";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./orders.module.css";
import { useNavigate } from 'react-router-dom';
import CustomPaginationTable from "../../components/paginationTable/CustomPaginationTable";
import {
    CircularProgress,
} from "@mui/material";
import { getSymbols } from "../../api/symbols/symbols.jsx";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { formatDateTime } from "../../utils/dateFormat";

function commitURL(value) {
    if (value = "unknown") {
        return value;
    }

    return (
        <a href={`https://github.com/lhcap/lhcap-trading/commit/${value}`} target="_blank" rel="noopener noreferrer">
            {value}
        </a>
    );
}

function toFixed(val, dec) {
    if (!val) {
        return val;
    }

    return val.toFixed(dec);
}

export const ordersColumns = [
    {
        name: "symbol",
        display: "Symbol",
        type: "string",
    },
    {
        name: "entry_side",
        display: "Entry Side",
        type: "string",
    },
    {
        name: "created_at",
        display: "Submit Time",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "filled_at",
        display: "Filled Time",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "limit_price",
        display: "Limit Price",
        type: "float",
        convertFunc: toFixed,
        decimals: 2,
    },
    {
        name: "entry_price",
        display: "Entry Price",
        type: "float",
        convertFunc: toFixed,
        decimals: 2,
    },
    {
        name: "profit",
        display: "Profit",
        type: "float",
        convertFunc: toFixed,
        decimals: 2,
        is_sortable: false,
    },
    {
        name: "exit_price",
        display: "Exit Price",
        type: "float",
    },
    {
        name: "exit_time",
        display: "Exit Time",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "duration",
        display: "Duration",
        type: "string",
        is_sortable: false,
    },
    {
        name: "trail_pct",
        display: "Trail Percentage",
        type: "float",
    },
    {
        name: "account_number",
        display: "Account Number",
        type: "string",
    },
    {
        name: "commit_hash",
        display: "Commit Hash",
        type: "string",
        convertFunc: commitURL,
    },
    {
        name: "contract_id",
        display: "Contract ID",
        type: "number",
    },
    {
        name: "dollar_value",
        display: "Dollar Value",
        type: "number",
    },
    {
        name: "event_names",
        display: "Event Names",
        type: "string",
    },
    {
        name: "qty",
        display: "Quantity",
        type: "number",
    },
    {
        name: "status",
        display: "Status",
        type: "string",
    },
    {
        name: "tif",
        display: "TIF",
        type: "string",
    },
];

function Orders() {
    const [selectedDate, setSelectedDate] = useState('');
    const [dates, setDates] = useState([]);
    const [ordersData, setOrdersData] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [isPaper, setIsPaper] = useState(false);
    const [symbol, setSymbol] = useState(null);

    const [avgProfit, setAvgProfit] = useState(0);
    const [symbols, setSymbols] = useState([]);

    useEffect(() => {
        const today = new Date();
        const tempDates = [];
        for (let i = 0; i < 100; i++) {
            const date = new Date();
            date.setDate(today.getDate() - i);
            tempDates.push(date.toISOString().split('T')[0]);
        }
        setDates(tempDates);
    }, []);

    useEffect(() => {
        if (ordersData && ordersData.data) {
            const totalProfit = ordersData.data.reduce((acc, order) => acc + (order.profit || 0), 0);
            const averageProfit = ordersData.data.length ? totalProfit / ordersData.data.length : 0;
            setAvgProfit((averageProfit * 100).toFixed(3));
        }
    }, [ordersData]);

    useEffect(() => {
        setPage(0);
    }, [selectedDate, isPaper, symbol]);

    const handleSelectChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleIsPaperChange = (event) => {
        setIsPaper(event.target.value);
    };

    const handleSend = async () => {
        await orders(selectedDate);
    };

    const navigate = useNavigate();
    const navigateToOrdersChart = useCallback(
        (order) => {
            navigate(`/orders/${order.id}`);
        },
        [navigate]
    );

    const fetchOrdersData = useCallback(async (page, pageSize, columnName, order) => {
        let response = await orders(selectedDate, isPaper, symbol?.id, page, pageSize, columnName, order);
        if (!response) {
            toast.error("no orders");
            return;
        }

        if (response.status !== 200) {
            toast.error(response?.data?.error);
            return;
        }

        setOrdersData(response.data);
    }, [selectedDate, isPaper, symbol]);

    const rowColoringFunction = (row) => {
        return row.profit < 0 ? '#FFCCCB' : '#4FFFB0';
    };

    const fetchSymbols = async (symbolBegining) => {
        let data = await getSymbols(symbolBegining);

        if (!data) {
            toast.error("failed to fetch symbols");
            return;
        }

        if (data.status !== 200) {
            toast.error(data?.data?.error);
            return;
        }

        setSymbols(data.data.symbols);
    };

    const handleSymbolInputChange = (event, newInputValue) => {
        if (newInputValue.length < 2) {
            setSymbols([]);
            return;
        }

        newInputValue = newInputValue.toUpperCase();
        fetchSymbols(newInputValue);
    };

    const handleSymbolChange = async (event, newValue) => {
        setSymbol(newValue);
    };

    return (
        <div style={{ width: "100%", height: "95vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "1rem" }}>
                <strong style={{ marginRight: "1rem" }}>Date :</strong>
                <select onChange={handleSelectChange} value={selectedDate} style={{ marginRight: "1rem" }}>
                    <option value="">Select a date</option>
                    {dates.map((date, index) => (
                        <option key={index} value={date}>{date}</option>
                    ))}
                </select>

                <strong style={{ marginRight: "1rem" }}>Is paper :</strong>
                <select onChange={handleIsPaperChange} value={isPaper} style={{ marginRight: "1rem" }}>
                    <option value="true">true</option>
                    <option value="false">false</option>
                </select>

                <strong style={{ marginRight: "1rem" }}>Symbol :</strong>
                <Autocomplete
                    className={styles["candlestick-form-input"]}
                    id="symbol-autocomplete"
                    options={symbols}
                    value={symbol}
                    getOptionLabel={(option) => option.symbol} // Use symbol for display
                    onInputChange={handleSymbolInputChange}
                    onChange={handleSymbolChange} // Use id for selection
                    renderInput={(params) => <TextField {...params} label="Symbol" />}
                    style={{ width: '200px', fontSize: '1.2rem' }}
                />
            </div>
            <div>
                <strong>Avg profit: {avgProfit}%</strong>
            </div>

            <div style={{ flex: 1, overflow: "hidden" }}>
                <CustomPaginationTable
                    data={ordersData?.data}
                    columns={ordersColumns}
                    total={ordersData?.total}
                    fetchDataRequest={fetchOrdersData}
                    rowClickRequest={navigateToOrdersChart}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rowColoringFunction={rowColoringFunction}
                />
            </div>
        </div>

    );
}

export default Orders;
