import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { candlestick } from "../../api/charts/candlestick";
import { toast } from "react-toastify";
import styles from "./lineseries.module.css";

const LineSeries = ({ chartData }) => {
    const chartContainerRef = useRef();
    const chart = useRef();

    useEffect(() => {
        if (!chartData || !chartData.data) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: true,
            },
        });

        const lineSeries = chart.current.addLineSeries({
            color: '#ff0000',
            lineWidth: 2,
        });
        lineSeries.setData(chartData.data)

        chart.current.timeScale().fitContent();

    }, [chartData]);

    return (
        <div>
            <div ref={chartContainerRef} className={styles["chart-container"]} />
        </div>
    )
};

export default LineSeries;
