import React, { useCallback, useRef, useEffect } from "react";
import { accounts } from "../../api/accounts/accounts";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./accounts.module.css";
import { useNavigate } from 'react-router-dom';
import CustomPaginationTable from "../../components/paginationTable/CustomPaginationTable";

function Orders() {
    const [accountsData, setAccountsData] = useState();

    const navigate = useNavigate();
    const navigateToAccountsChart = useCallback(
        async (order) => {
            navigate(`/accounts/${order.id}`);
        },
        []
    );

    const fetchAccountsData = useCallback(async (page, pageSize, columnName, order) => {
        let response = await accounts(page, pageSize, columnName, order);
        if (!response) {
            toast.error("no accounts");
            return;
        }

        if (response.status !== 200) {
            toast.error(response?.data?.error);
            return;
        }

        setAccountsData(response.data);
    }, []);

    return (
        <div>
            <CustomPaginationTable
                data={accountsData?.data}
                total={accountsData?.total}
                fetchDataRequest={fetchAccountsData}
                rowClickRequest={navigateToAccountsChart}
            />
        </div>
    );
}

export default Orders;