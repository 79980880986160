import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { candlestick } from '../../api/charts/candlestick';
import { portfolioValuesById, portfolioById } from '../../api/portfolios/portfolios';
import { toast } from 'react-toastify';
import LineSeries from '../../components/line/LineSeries';
import PortfolioChart from '../../components/portfolio/PortfolioChart';
import { Select, MenuItem, Box, CircularProgress, Button } from '@mui/material';
import styles from './portfolioDetail.module.css';
import { backtestById } from "../../api/backtests/backtests";
import CustomTable from "../../components/customTable/customTable";

function PortfolioDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [shownPortfolio, setShownPortfolio] = useState();
    const [portfolioChartData, setPortfolioChartData] = useState();
    const [loadingPortfolio, setLoadingPortfolio] = useState(false);

    const GetPortfolioChart = useCallback(async (portfolio_id) => {
        if (!portfolio_id) {
            return;
        }

        setLoadingPortfolio(true);
        setPortfolioChartData(null);

        let resp = await portfolioById(portfolio_id)
        if (!resp) {
            toast.error("timeout");
            setPortfolioChartData(null);
            return;
        }

        if (resp.status !== 200) {
            toast.error(resp?.data?.error);
            setPortfolioChartData(null);
            return;
        }

        setShownPortfolio(resp.data)

        let portfolioResponse = await portfolioValuesById(portfolio_id);
        setLoadingPortfolio(false);
        if (!portfolioResponse || !portfolioResponse.data) {
            toast.error("timeout");
            setPortfolioChartData(null);
            return;
        }

        if (portfolioResponse.status !== 200) {
            toast.error(portfolioResponse?.data?.error);
            setPortfolioChartData(null);
            return;
        }

        if (portfolioResponse.data.length === 0) {
            toast.error("no portfolio values for that portfolio");
            setPortfolioChartData(null);
            return;
        }

        setPortfolioChartData(portfolioResponse);
    }, []);

    const fetchBacktestData = async (id) => {
        let res = await backtestById(id)
        if (!res || res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }
    };

    useEffect(() => {
        if (id) {
            GetPortfolioChart(+id);
        }
    }, [id]);

    return (
        <div className={styles["portfolioDetail-container"]}>
            <div className={styles["portfolioDetail-header"]}>
                <Button onClick={() => navigate('/portfolios')}>Back</Button>
                {shownPortfolio && <CustomTable rows={[shownPortfolio]} />}
            </div>
            <div className={styles["portfolioDetail-content"]}>
                {loadingPortfolio && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                )}
                {portfolioChartData && (
                    <LineSeries chartData={portfolioChartData}></LineSeries>
                )}
            </div>
        </div>
    );
}

export default PortfolioDetail;
