import React from "react";
import { Navigate, Outlet } from 'react-router-dom';

const isTokenValid = () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
        console.error("Wrong token");
        return false;
    }
    return true;
}

export const ProtectedRoute = () => {
    const isLoggedIn = isTokenValid();
    return isLoggedIn ? <Outlet /> : <Navigate to='/login' />;
};
