import React from "react";
import { movingAverage } from "../../api/charts/movingAverage.jsx";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { getSymbols, getBacktestsBySymbol } from "../../api/symbols/symbols.jsx";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTable from "../../components/customTable/customTable.jsx";
import MovingAveragesHistogramsChart from "../../components/movingAverageHistogram/MovingAveragesHistogramsChart.jsx";
import styles from "./movingAverage.module.css";

function MovingAverageChart() {
    const [chartData, setChartData] = useState();
    const [symbols, setSymbols] = useState([]);

    const [backtests, setBacktests] = useState([]);
    const [formValues, setFormValues] = useState({
        symbol: null,
        backtest_id: null,
    });

    const [shownBacktest, setShownBacktest] = useState({});

    const validateForm = (values) => {
        if (values.symbol == null || values.symbol === "") {
            toast.error("symbol cannot be empty");
            return false;
        }

        return true;
    };

    const fetchMovingAverageData = async (event) => {
        event.preventDefault();
        const isValid = validateForm(formValues);
        if (!isValid) {
            return;
        }

        let movingAverageResponse = await movingAverage({
            symbol: formValues.symbol,
            backtest_id: formValues.backtest_id,
        });

        if (!movingAverageResponse || !movingAverageResponse.data) {
            toast.error("timeout");
            setChartData(null);
            return;
        }

        if (
            movingAverageResponse.data.moving_average_10.length === 0 &&
            movingAverageResponse.data.moving_average_50.length === 0 &&
            movingAverageResponse.data.moving_average_200.length === 0
        ) {
            toast.error("not enough data to calculate moving average");
            setChartData(null);
            return;
        }

        if (movingAverageResponse.status !== 200) {
            toast.error(movingAverageResponse?.data?.error);
            setChartData(null);
            return;
        }

        setChartData(movingAverageResponse);
    };

    const fetchSymbols = async (symbolBegining) => {
        let data = await getSymbols(symbolBegining);

        if (!data) {
            toast.error("failed to fetch symbols");
            return;
        }

        if (data.status !== 200) {
            toast.error(data?.data?.error);
            return;
        }

        setSymbols(data.data.symbols);
    };

    const handleSymbolInputChange = (event, newInputValue) => {
        if (newInputValue.length < 2) {
            setSymbols([]);
            return;
        }

        newInputValue = newInputValue.toUpperCase();
        fetchSymbols(newInputValue);
    };

    const handleSymbolChange = async (event, newValue) => {
        let data = await getBacktestsBySymbol(newValue);
        if (data.status !== 200) {
            toast.error(data?.data?.error);
            return;
        }

        setFormValues((prevState) => ({
            ...prevState,
            backtest_id: null,
            symbol: newValue,
        }));
        setBacktests(data?.data?.backtests);
    };

    const handleBacktestChange = (event, newValue) => {
        setShownBacktest(backtests.find((backtest) => backtest.backtest_id === newValue));

        setFormValues({
            ...formValues,
            backtest_id: newValue,
        });
    };

    return (
        <div className={styles["candlestick-container"]}>
            <div>
                <form onSubmit={fetchMovingAverageData}>
                    <Stack spacing={2}>
                        <h2>Moving Average chart</h2>
                        <div className={styles["candlestick-form"]}>
                            <Autocomplete
                                className={styles["candlestick-form-input"]}
                                id="symbol-autocomplete"
                                options={symbols}
                                value={formValues.symbol}
                                getOptionLabel={(option) => option}
                                onInputChange={handleSymbolInputChange}
                                onChange={handleSymbolChange}
                                renderInput={(params) => <TextField {...params} label="Symbol" />}
                            />
                            <Autocomplete
                                className={styles["candlestick-form-input"]}
                                id="backtest-autocomplete"
                                options={backtests.map((backtest) => backtest.backtest_id)}
                                value={formValues.backtest_id}
                                getOptionLabel={(option) => option.toString()}
                                onChange={handleBacktestChange}
                                renderInput={(params) => <TextField {...params} label="Backtest id" />}
                            />
                        </div>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Generate chart
                        </Button>
                    </Stack >
                </form >
            </div >

            <div>
                <CustomTable rows={[shownBacktest]}></CustomTable>
            </div>

            {
                chartData && (
                    <>
                        <MovingAveragesHistogramsChart chartData={chartData}></MovingAveragesHistogramsChart>

                        <div
                            style={{
                                color: "white",
                                backgroundColor: "#253248",
                                padding: "10px",
                            }}
                        >
                            <div style={{ color: "#2962FF" }}>10-Day Moving Average</div>
                            <div style={{ color: "red" }}>50-Day Moving Average</div>
                            <div style={{ color: "#00FF00" }}>200-Day Moving Average</div>
                        </div >
                    </>
                )
            }
        </div >
    );
}

export default MovingAverageChart;
