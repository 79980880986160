import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import styles from "./histogramChart.module.css";

const HistogramChart = ({ chartData }) => {
    const chartContainerRef = useRef();
    const chart = useRef();

    useEffect(() => {
        if (!chartData) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                vertLine: {
                    labelVisible: true,
                },
                horzLine: {
                    labelVisible: true,
                },
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: false,
                tickMarkFormatter: (time) => {
                    const date = new Date(time * 1000);  // Assuming UNIX timestamp
                    const hours = date.getUTCHours();
                    const minutes = date.getUTCMinutes();

                    // Convert to New York time
                    const nyTime = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                        timeZone: 'America/New_York'
                    }).format(date);

                    return nyTime;
                },
            },
        });
        const histogramSeries = chart.current.addHistogramSeries({
            color: "#2962FF",
            priceScaleId: 'right',
        });

        chart.current.applyOptions({
            localization: {
                timeFormatter: (businessDayOrTimestamp) => {
                    const date = new Date(businessDayOrTimestamp * 1000);  // Assuming UNIX timestamp
                    const hours = date.getUTCHours();
                    const minutes = date.getUTCMinutes();

                    // Convert to New York time
                    const nyTime = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                        timeZone: 'America/New_York'
                    }).format(date);

                    return nyTime;
                },
            },
        });


        histogramSeries.setData(chartData);
        chart.current.timeScale().fitContent();
    }, [chartData]);

    return <div ref={chartContainerRef} className={styles["chart-container"]} />;
};

export default HistogramChart;
