import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { InsertChart } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import styles from "./layout.module.css";
import MoneyIcon from '@mui/icons-material/Money';
const drawerWidth = 240;
const collapsedDrawerWidth = 50;

function LayoutComponent() {
    const navigate = useNavigate();
    const [selectedRoute, setSelectedRoute] = useState();
    const [isExtended, setIsExtended] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setSelectedRoute(location.pathname);
    }, [location.pathname]);

    const toggleDrawer = () => {
        setIsExtended(!isExtended);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: isExtended ? drawerWidth : collapsedDrawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: isExtended ? drawerWidth : collapsedDrawerWidth,
                        boxSizing: "border-box",
                        overflowX: "hidden",
                        transition: "width 0.3s",
                    },
                }}
                variant="permanent"
                open={true}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: isExtended ? "flex-end" : "center",
                        p: 1,
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Box>
                <List>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", textAlign: isExtended ? "left" : "center" }}
                        className={selectedRoute === "/backtests" && styles["selected-icon"]}
                        onClick={() => navigate("/backtests")}
                    >
                        <ListItemButton sx={{ justifyContent: isExtended ? "initial" : "center", px: 2.5 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: isExtended ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <InsertChart />
                            </ListItemIcon>
                            {isExtended && <ListItemText primary="Backtests" />}
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", textAlign: isExtended ? "left" : "center" }}
                        className={selectedRoute === "/orders" && styles["selected-icon"]}
                        onClick={() => navigate("/orders")}
                    >
                        <ListItemButton sx={{ justifyContent: isExtended ? "initial" : "center", px: 2.5 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: isExtended ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            {isExtended && <ListItemText primary="Orders" />}
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", textAlign: isExtended ? "left" : "center" }}
                        className={selectedRoute === "/portfolios" && styles["selected-icon"]}
                        onClick={() => navigate("/portfolios")}
                    >
                        <ListItemButton sx={{ justifyContent: isExtended ? "initial" : "center", px: 2.5 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: isExtended ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <MoneyIcon />
                            </ListItemIcon>
                            {isExtended && <ListItemText primary="Portfolios" />}
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", textAlign: isExtended ? "left" : "center" }}
                        className={selectedRoute === "/accounts" && styles["selected-icon"]}
                        onClick={() => navigate("/accounts")}
                    >
                        <ListItemButton sx={{ justifyContent: isExtended ? "initial" : "center", px: 2.5 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: isExtended ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <ElectricalServicesIcon />
                            </ListItemIcon>
                            {isExtended && <ListItemText primary="Accounts" />}
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", textAlign: isExtended ? "left" : "center" }}
                        className={selectedRoute === "/settings" && styles["selected-icon"]}
                        onClick={() => navigate("/settings")}
                    >
                        <ListItemButton sx={{ justifyContent: isExtended ? "initial" : "center", px: 2.5 }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: isExtended ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <SettingsIcon />
                            </ListItemIcon>
                            {isExtended && <ListItemText primary="Settings" />}
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}

export default LayoutComponent;
