import React, { useState } from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LayoutComponent from './components/layout/Layout.jsx'
import CandlestickChart from './pages/candlestick/CandlestickChart'
import Settings from './pages/settings/Settings.jsx'
import PortfolioChart from './pages/portfolioChart/PortfolioChart'
import MovingAverageChart from './pages/movingAverage/MovingAverageChart.jsx'
import { Login } from './pages/login/Login.jsx'
import { ProtectedRoute } from './utils/ProtectedRoute.jsx'
import Backtests from './pages/backtests/Backtests.jsx'
import NotFound from './pages/notFound/NotFound.jsx'
import Home from './pages/home/Home.jsx'
import Orders from './pages/orders/Orders.jsx'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import BackTest from './pages/backtest/BackTest.jsx'
import OrderDetail from './pages/orderDetail/OrderDetail.jsx'
import AccountDetail from './pages/accountDetail/AccountDetail.jsx';
import Portfolios from "./pages/portfolios/Portfolios.jsx";
import PortfolioDetail from "./pages/portfolioDetail/PortfolioDetail.jsx";
import Accounts from './pages/accounts/Accounts.jsx'

function App() {
    const [isLogged, setIsLogged] = useState(false)

    const LogState = () => {
        setIsLogged(true)
    }

    const router = createBrowserRouter([
        {
            path: '/login',
            element: <Login logState={LogState} />
        },

        {
            path: '/',
            element: <LayoutComponent />,
            children: [
                {
                    path: '/',
                    element: <ProtectedRoute />,
                    children: [
                        { path: '/', element: <Home /> },
                        // { path: '/candlestick', element: <CandlestickChart /> },
                        // { path: '/portfolio', element: <PortfolioChart /> },
                        // { path: '/moving-average', element: <MovingAverageChart /> },
                        { path: "/portfolios", element: <Portfolios /> },
                        { path: "/portfolios/:id", element: <PortfolioDetail /> },
                        { path: '/backtests', element: <Backtests /> },
                        { path: '/backtests/:id', element: <BackTest /> },
                        { path: '/accounts', element: <Accounts /> },
                        { path: '/accounts/:id', element: <AccountDetail /> },
                        { path: '/orders', element: <Orders /> },
                        { path: '/orders/:id', element: <OrderDetail /> },
                        { path: '/settings', element: <Settings /> },
                        { path: '*', element: <NotFound /> }
                    ]
                }

            ]
        }
    ])

    return (
        <div className="App">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
                <ToastContainer position="bottom-right" theme="colored" limit={4} />
            </LocalizationProvider>
        </div>
    )
}

export default App
