import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    CircularProgress,
} from "@mui/material";
import styles from "./customPaginationTable.module.css";

const CustomPaginationTable = ({
    data,
    columns,
    total,
    fetchDataRequest,
    rowClickRequest,
    page,
    setPage,
    pageSize,
    setPageSize,
    rowColoringFunction, // Add the rowColoringFunction prop
    defaultSortColumn,
    defaultSortOrder     // Add defaultSortOrder prop
}) => {
    const [orderByColumn, setOrderByColumn] = useState(defaultSortColumn || null);
    const [order, setOrder] = useState(defaultSortOrder || "asc");
    const [clickedRowId, setClickedRow] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchDataRequest(page, pageSize, orderByColumn, order).finally(() => {
            setLoading(false);
        });
    }, [fetchDataRequest, page, pageSize, orderByColumn, order]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setClickedRow(null);
    };

    const handlePageSize = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (column) => {
        const isAsc = orderByColumn === column && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrderByColumn(column);
        setOrder(newOrder);
        setClickedRow(null);
    };

    const handleRowClick = (row) => {
        setClickedRow((prevClickedRowId) => {
            const newClickedRowId = prevClickedRowId === row.rowId ? null : row.rowId;
            rowClickRequest(row);
            return newClickedRowId;
        });
    };

    function convertColumnValue(column, row) {
        let value = row[column.name];

        switch (column.type) {
            case 'number':
                return (typeof column.convertFunc === 'function' ? column.convertFunc(value) : value);
            case 'float':
                return (typeof column.convertFunc === 'function' ? column.convertFunc(value, column.decimals) : value);
            case 'string':
                return (typeof column.convertFunc === 'function' ? column.convertFunc(value) : value);
            case 'date':
                return (typeof column.convertFunc === 'function' ? column.convertFunc(value) : value);
            default:
                return value;
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <>
                <TableContainer component={Paper} className={styles.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow className={styles.stickyHeader}>
                                {columns.map((column) => (
                                    <TableCell key={column.name}
                                        sx={{ padding: "4px" }}
                                        className={styles.smallRowHeight}
                                    >
                                        {column?.is_sortable !== false ? (
                                            <TableSortLabel
                                                active={orderByColumn === column.name}
                                                direction={orderByColumn === column.name ? order : "asc"}
                                                onClick={() => handleSort(column.name)}
                                            >
                                                <strong>{column.display}</strong>
                                            </TableSortLabel>
                                        ) : (
                                            <strong>{column.display}</strong>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data?.map((row) => (
                                    <TableRow
                                        key={row.rowId}
                                        selected={row.rowId === clickedRowId}
                                        hover
                                        onClick={() => handleRowClick(row)}
                                        sx={{
                                            '& .MuiTableCell-root': { padding: "4px 4px" },
                                            backgroundColor: rowColoringFunction ? rowColoringFunction(row) : 'transparent',
                                            '&.Mui-selected': {
                                                backgroundColor: rowColoringFunction ? `${rowColoringFunction(row)} !important` : 'transparent !important',
                                                '&:hover': {
                                                    backgroundColor: rowColoringFunction ? rowColoringFunction(row) : 'transparent'
                                                }
                                            }
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={`${row.rowId}-${column.name}`} className={styles.smallRowHeight}>
                                                {convertColumnValue(column, row)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 300]}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handlePageSize}
                />
            </>
        </div>
    );
};

export default CustomPaginationTable;