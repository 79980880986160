import { client } from "../../axios/axios";

export const backtests = (page, pageSize, columnName, order) => {
    let url = "api/backtests";

    const queryParams = new URLSearchParams();

    queryParams.append("page", page);
    queryParams.append("page_size", pageSize);

    if (columnName && order) {
        queryParams.append("column_name", columnName);
        queryParams.append("order", order);
    }

    url += "?" + queryParams.toString();


    return client.get(url);
};

export const backtestById = (id) => {
    let url = "api/backtests/" + id;
    return client.get(url);
};
