import axios from "axios";

export const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 20000,
});

client.interceptors.request.use(
    (config) => {
        // const accessToken = localStorage.getItem("access_token");
        // const refreshToken = localStorage.getItem("refresh_token");
        // if (accessToken && refreshToken) {
        //     config.headers.set("Auth-Access-Token", accessToken);
        //     config.headers.set("Auth-Refresh-Token", refreshToken);
        // }

        const token = localStorage.getItem('userToken');
        config.headers.set("Authorization", token)

        config.headers.set("Content-Type", "application/json");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response) => {
        // const accessToken = response.headers.get("Auth-Access-Token");
        // const refreshToken = response.headers.get("Auth-Refresh-Token");
        // if (accessToken && refreshToken) {
        //     localStorage.setItem("access_token", accessToken);
        //     localStorage.setItem("refresh_token", refreshToken);
        // }

        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            localStorage.removeItem('userToken');
            window.location.reload();
        }
        return error.response;
    }
);
