import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { candlestick } from '../../api/charts/candlestick';
import { orderById } from '../../api/orders/orders';

import { movingAverage } from '../../api/charts/movingAverage';
import { portfolio } from '../../api/charts/portfolio';
import { toast } from 'react-toastify';
import CandlestickSecs from '../../components/candlestick/CandlestickSecs';
import MovingAveragesHistogramsChart from '../../components/movingAverageHistogram/MovingAveragesHistogramsChart';
import PortfolioChart from '../../components/portfolio/PortfolioChart';
import { Select, MenuItem, Box, CircularProgress, Button } from '@mui/material';
import styles from './orderDetail.module.css';
import { backtestById } from "../../api/backtests/backtests";
import CustomTable from "../../components/customTable/customTable";

function OrderDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    // const [shownBacktest, setShownBacktest] = useState();
    const [candlestickChartData, setCandlestickChartData] = useState();
    const [loadingCandlestick, setLoadingCandlestick] = useState(false);

    const GetCandlestickChart = useCallback(async (order_id) => {
        if (!order_id) {
            return;
        }

        setLoadingCandlestick(true);
        setCandlestickChartData(null);

        let resp = await orderById(order_id)
        if (!resp) {
            toast.error("timeout");
            setCandlestickChartData(null);
            return;
        }

        if (resp.status !== 200) {
            toast.error(resp?.data?.error);
            setCandlestickChartData(null);
            return;
        }

        let candlestickResponse = await candlestick({
            asset_id: resp.data.asset_id,
            date: resp.data.created_at,
            seconds: true
        });
        setLoadingCandlestick(false);
        if (!candlestickResponse || !candlestickResponse.data) {
            toast.error("timeout");
            setCandlestickChartData(null);
            return;
        }

        if (candlestickResponse.status !== 200) {
            toast.error(candlestickResponse?.data?.error);
            setCandlestickChartData(null);
            return;
        }

        if (candlestickResponse.data.bars.length === 0) {
            toast.error("no bars for that asset");
            setCandlestickChartData(null);
            return;
        }

        candlestickResponse.asset_id = resp.data.asset_id;
        setCandlestickChartData(candlestickResponse);
    }, []);

    const fetchBacktestData = async (id) => {
        let res = await backtestById(id)
        if (!res || res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }
    };

    useEffect(() => {
        if (id) {
            GetCandlestickChart(+id);
        }
    }, [id]);

    return (
        <div className={styles["backtestDetail-container"]}>
            <div className={styles["backtestDetail-header"]}>
                <Button onClick={() => navigate('/orders')}>Back</Button>
                {/* {shownBacktest && <CustomTable rows={[shownBacktest]} />} */}
            </div>
            <div className={styles["backtestDetail-content"]}>
                {loadingCandlestick && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                )}
                {candlestickChartData && (
                    <CandlestickSecs chartData={candlestickChartData}></CandlestickSecs>
                )}
            </div>
        </div>
    );
}

export default OrderDetail;
