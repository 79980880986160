import { client } from "../../axios/axios";

export const portfolios = (page, pageSize, columnName, order) => {
    let url = "api/portfolios";

    const queryParams = new URLSearchParams();

    queryParams.append("page", page);
    queryParams.append("page_size", pageSize);

    if (columnName && order) {
        queryParams.append("column_name", columnName);
        queryParams.append("order", order);
    }

    url += "?" + queryParams.toString();


    return client.get(url);
};

export const portfolioValuesById = (id) => {
    let url = "api/portfolios/" + id + "/values";
    return client.get(url);
};

export const portfolioById = (id) => {
    let url = "api/portfolios/" + id;
    return client.get(url);
};
