import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { candlestick } from "../../api/charts/candlestick";
import { toast } from "react-toastify";
import styles from "./candlestick.module.css";

const Candlestick = ({ chartData }) => {
    const chartContainerRef = useRef();
    const chartSecondsContainerRef = useRef();

    const chart = useRef();
    const chartSeconds = useRef();

    const barDurations = ["1-mins", "3-mins", "5-mins", "10-mins", "15-mins", "30-mins", "60-mins", "1-days"];

    const currentFormValuesRef = useRef({
        symbol: null,
        bar_duration: barDurations[0],
        backtest_id: null,
        date: null,
        current_bars: [],
    });

    useEffect(() => {
        if (!chartData || !chartData.data) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        currentFormValuesRef.current = {
            ...currentFormValuesRef.current,
            date: chartData.data.start_date,
            backtest_id: chartData.backtest_id,
            asset_id: chartData.asset_id,
            current_bars: chartData.data.bars,
        };

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: false,
            },
        });

        const volumeSeries = chart.current.addHistogramSeries({
            color: "#182233",
            lineWidth: 2,
            priceFormat: {
                type: "volume",
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
            priceScaleId: "volume",
        });

        volumeSeries.setData(
            chartData.data.bars.map((bar) => ({
                time: bar.time,
                value: bar.volume,
            }))
        );

        const series = chart.current.addCandlestickSeries({
            upColor: "#26a69a",
            downColor: "#ef5350",
            borderVisible: false,
            wickUpColor: "#26a69a",
            wickDownColor: "#ef5350",
        });

        series.setData(chartData.data.bars);

        let markers = [];
        let orders = chartData?.data?.orders;
        let id = 0;
        for (let i = 0; i < orders.length; i++) {
            id += 1;
            markers.push({
                id: id,
                time: orders[i].buy_date_unix,
                position: "belowBar",
                color: "#2196F3",
                shape: "arrowUp",
                text: "Buy",
            });

            markers.push({
                id: id,
                time: orders[i].sell_date_unix,
                position: "aboveBar",
                color: "#e91e63",
                shape: "arrowDown",
                text: "Sell",
            });
        }

        markers.sort((a, b) => {
            return a.time - b.time;
        });

        series.setMarkers(markers);
        const onVisibleLogicalRangeChanged = (newVisibleLogicalRange) => {
            const barsInfo = series.barsInLogicalRange(newVisibleLogicalRange);

            if (barsInfo !== null && barsInfo.barsBefore < 200 && !currentFormValuesRef.current.is_updated) {
                currentFormValuesRef.current.is_updated = true;
                candlestick({
                    asset_id: currentFormValuesRef.current.asset_id,
                    bar_duration: currentFormValuesRef.current.bar_duration,
                    backtest_id: currentFormValuesRef.current.backtest_id,
                    date: currentFormValuesRef.current.date,
                })
                    .then((data) => {
                        if (data === null) {
                            toast.error("timeout");
                            return;
                        }

                        if (data.status !== 200) {
                            toast.error(data?.data?.error);
                            return;
                        }

                        currentFormValuesRef.current.current_bars = [
                            ...data.data.bars,
                            ...currentFormValuesRef.current.current_bars,
                        ];
                        currentFormValuesRef.current.date = data.data.start_date;
                        series.setData(currentFormValuesRef.current.current_bars);
                        volumeSeries.setData(
                            currentFormValuesRef.current.current_bars.map((bar) => ({
                                time: bar.time,
                                value: bar.volume,
                            }))
                        );
                    })
                    .finally(() => {
                        currentFormValuesRef.current.is_updated = false;
                    });
            }
        };

        const handleClick = (param) => {
            if (!param || !param.hoveredObjectId) return;

            let found = [];
            for (const marker of markers) {
                if (marker.id === param.hoveredObjectId) {
                    found.push(marker)
                }
            }

            if (found.length !== 0) {
                generateSeconds(found)
            }
        };
        // chartContainerRef.current.addEventListener('click', handleClick);

        chart.current.subscribeClick(handleClick);
        chart.current.timeScale().subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged);
    }, [chartData]);


    const generateSeconds = async (chosenTrades) => {
        const lowestTimeTrade = chosenTrades.reduce((lowest, trade) => {
            return trade.time < lowest.time ? trade : lowest;
        }, chosenTrades[0]);

        // Convert Unix timestamp to date format 2024-05-04T00:00:00Z
        const date = new Date(lowestTimeTrade.time * 1000);
        const formattedDate = date.toISOString();
        let res = await candlestick({
            asset_id: currentFormValuesRef.current.asset_id,
            bar_duration: currentFormValuesRef.current.bar_duration,
            backtest_id: currentFormValuesRef.current.backtest_id,
            date: formattedDate,
            seconds: true,
        })

        if (!res) {
            toast.error("timeout");
            return;
        }

        if (res.status !== 200) {
            toast.error(res?.data?.error);
            return;
        }

        let chartData = res;

        if (chartSeconds.current) {
            chartSeconds.current.remove();
        }

        // currentFormValuesRef.current = {
        //     ...currentFormValuesRef.current,
        //     date: chartData.data.start_date,
        //     backtest_id: chartData.backtest_id,
        //     asset_id: chartData.asset_id,
        //     current_bars: chartData.data.bars,
        // };

        chartSeconds.current = createChart(chartSecondsContainerRef.current, {
            width: chartSecondsContainerRef.current.clientWidth,
            height: chartSecondsContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: false,
            },
        });

        const volumeSeries = chartSeconds.current.addHistogramSeries({
            color: "#182233",
            lineWidth: 2,
            priceFormat: {
                type: "volume",
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
            priceScaleId: "volume",
        });

        volumeSeries.setData(
            chartData.data.bars.map((bar) => ({
                time: bar.time,
                value: bar.volume,
            }))
        );

        const series = chartSeconds.current.addCandlestickSeries({
            upColor: "#26a69a",
            downColor: "#ef5350",
            borderVisible: false,
            wickUpColor: "#26a69a",
            wickDownColor: "#ef5350",
        });

        series.setData(chartData.data.bars);

        let markers = [];
        let orders = chartData?.data?.orders;
        let id = 0;
        markers.push(chosenTrades[0]);
        markers.push(chosenTrades[1]);

        markers.sort((a, b) => {
            return a.time - b.time;
        });

        series.setMarkers(markers);
        // const onVisibleLogicalRangeChanged = (newVisibleLogicalRange) => {
        //     const barsInfo = series.barsInLogicalRange(newVisibleLogicalRange);

        //     if (barsInfo !== null && barsInfo.barsBefore < 200 && !currentFormValuesRef.current.is_updated) {
        //         currentFormValuesRef.current.is_updated = true;
        //         candlestick({
        //             asset_id: currentFormValuesRef.current.asset_id,
        //             bar_duration: currentFormValuesRef.current.bar_duration,
        //             backtest_id: currentFormValuesRef.current.backtest_id,
        //             date: currentFormValuesRef.current.date,
        //         })
        //             .then((data) => {
        //                 if (data === null) {
        //                     toast.error("timeout");
        //                     return;
        //                 }

        //                 if (data.status !== 200) {
        //                     toast.error(data?.data?.error);
        //                     return;
        //                 }

        //                 currentFormValuesRef.current.current_bars = [
        //                     ...data.data.bars,
        //                     ...currentFormValuesRef.current.current_bars,
        //                 ];
        //                 currentFormValuesRef.current.date = data.data.start_date;
        //                 series.setData(currentFormValuesRef.current.current_bars);
        //                 volumeSeries.setData(
        //                     currentFormValuesRef.current.current_bars.map((bar) => ({
        //                         time: bar.time,
        //                         value: bar.volume,
        //                     }))
        //                 );
        //             })
        //             .finally(() => {
        //                 currentFormValuesRef.current.is_updated = false;
        //             });
        //     }
        // };
    };

    return (
        <div>
            <div ref={chartContainerRef} className={styles["chart-container"]} />


            <br></br>
            <b>Bars for a chosen trade (CLICK ON TRADE MARKER TO GENERATE TRADE)</b>
            <div ref={chartSecondsContainerRef} className={styles["chart-container"]} />
        </div>
    )
};

export default Candlestick;
