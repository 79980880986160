import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CustomPaginationTable from "../../components/paginationTable/CustomPaginationTable";
import { backtests as backtestsApiCall } from "../../api/backtests/backtests";
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from "../../utils/dateFormat";

function toFixed(val, dec) {
    if (!val) {
        return val;
    }

    return val.toFixed(dec);
}


function annld(val, dec) {
    if (!val) {
        return val;
    }

    return val - 1;
}

export const backtestsColumns = [
    {
        name: "symbol",
        display: "Sym",
        type: "string",
    },
    {
        name: "event_names",
        display: "Evt Names",
        type: "string",
    },
    {
        name: "entry_side",
        display: "Side",
        type: "string",
    },
    {
        name: "trail_percentage",
        display: "Trail %",
        type: "float",
    },
    {
        name: "take_profit_pct",
        display: "Take Prof %",
        type: "float",
    },
    {
        name: "number_of_trades",
        display: "Num trades",
        type: "float",
    },
    {
        name: "percentage_profitable",
        display: "% profitable",
        type: "float",
        convertFunc: toFixed,
        decimals: 2,
    },
    {
        name: "percentage_time_invested",
        display: "% time invested",
        type: "float",
        convertFunc: toFixed,
        decimals: 3,
    },
    {
        name: "trades_per_month",
        display: "Trades/month",
        type: "float",
    },
    {
        name: "annld_return",
        display: "Annld",
        type: "float",
        convertFunc: annld,
        decimals: 2,
    },
    {
        name: "last_10_percentage_returns",
        display: "Avg last 10",
        type: "float",
        convertFunc: toFixed,
        decimals: 4,
    },
    {
        name: "last_50_percentage_returns",
        display: "Avg last 50",
        type: "float",
        convertFunc: toFixed,
        decimals: 4,
    },
    {
        name: "last_100_percentage_returns",
        display: "Avg last 100",
        type: "float",
        convertFunc: toFixed,
        decimals: 4,
    },
    {
        name: "last_500_percentage_returns",
        display: "Avg last 500",
        type: "float",
        convertFunc: toFixed,
        decimals: 4,
    },
    {
        name: "last_1000_percentage_returns",
        display: "Avg last 1000",
        type: "float",
        convertFunc: toFixed,
        decimals: 5,
    },
    {
        name: "last_updated",
        display: "Last update",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "first_event_date",
        display: "First evt date",
        type: "date",
        convertFunc: formatDateTime,
    },
    {
        name: "latest_event_date",
        display: "Latest evt date",
        type: "date",
        convertFunc: formatDateTime,
    },
];

function Backtests() {
    const [backtestsData, setBacktestsData] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(500);

    const navigate = useNavigate();
    const fetchChartData = useCallback(
        async (backtest) => {
            navigate(`/backtests/${backtest.backtest_id}`);
        },
        []
    );

    const fetchBacktestData = useCallback(async (page, pageSize, columnName, order) => {
        let response = await backtestsApiCall(page, pageSize, columnName, order);
        if (!response) {
            toast.error("no backtests");
            return;
        }

        if (response.status !== 200) {
            toast.error(response?.data?.error);
            return;
        }

        setBacktestsData(response.data);
    }, []);

    return (
        <div style={{ width: "100%", height: "95vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2>BackTests</h2>
            <div style={{ flex: 1, overflow: "hidden" }}>
                <CustomPaginationTable
                    data={backtestsData?.data}
                    total={backtestsData?.total}
                    fetchDataRequest={fetchBacktestData}
                    rowClickRequest={fetchChartData}
                    columns={backtestsColumns}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    defaultSortColumn={"last_1000_pct_rets"}
                    defaultSortOrder={"desc"}
                />
            </div>
        </div>
    );
}

export default Backtests;


