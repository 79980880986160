import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const routes = [
        { path: "/ ", name: "Home" },
        { path: "/portfolios", name: "Portfolios List" },
        { path: "/portfolios/:id", name: "Single Portfolio Values" },
        { path: "/backtests", name: "Backtests List" },
        { path: "/backtests/:id", name: "Single BackTests Details" },
        { path: "/accounts", name: "Broker Accounts" },
        { path: "/accounts/:id", name: "Broker Account Detail" },
        { path: "/orders", name: "Orders List" },
        { path: "/orders/:id", name: "Single Order Detail" },
        { path: "/settings", name: "Settings" },
    ];

    return (
        <div >
            <h2>Page Not Found</h2>
            <p>The page you are looking for does not exist. Here are the options:</p>
            <ul>
                {routes.map((route) => (
                    <li key={route.path}>
                        <Link to={route.path}>{route.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotFound;