import React, { useEffect, useRef } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { toast } from "react-toastify";
import styles from "./tradesCandlestick.module.css";
import { formatDateTime } from "../../utils/dateFormat";

const TradesCandlestick = ({ trade, tradeNumber }) => {
    const chartContainerRef = useRef();
    const chart = useRef();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return Math.floor(date.getTime() / 1000);
    };

    useEffect(() => {
        if (!trade || !trade.bars) {
            return;
        }

        if (chart.current) {
            chart.current.remove();
        }

        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: "#253248" },
                textColor: "white",
            },
            grid: {
                vertLines: {
                    color: "#334158",
                },
                horzLines: {
                    color: "#334158",
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#485c7b",
            },
            timeScale: {
                borderColor: "#485c7b",
                timeVisible: true,
                secondsVisible: true,
            },
        });

        const volumeSeries = chart.current.addHistogramSeries({
            color: "#182233",
            lineWidth: 2,
            priceFormat: {
                type: "volume",
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
            priceScaleId: "volume",
        });

        volumeSeries.setData(
            trade.bars.map((bar) => ({
                time: bar.time,
                value: bar.volume,
            }))
        );

        const series = chart.current.addCandlestickSeries({
            upColor: "#26a69a",
            downColor: "#ef5350",
            borderVisible: false,
            wickUpColor: "#26a69a",
            wickDownColor: "#ef5350",
        });

        series.setData(trade.bars);

        const trailPriceSeries = chart.current.addLineSeries({
            color: '#ff0000',
            lineWidth: 2,
        });

        let highestTrail = -Infinity;
        const startTime = trade.start_time_unix;
        const trailPrices = trade.bars
            .filter(bar => bar.time >= startTime)
            .map((bar) => {
                const entryPriceAdjustment = trade.entry_side.toLowerCase() === 'short' ? 1 + trade.trail_percentage : 1 - trade.trail_percentage;
                const trailPrice = bar.high * entryPriceAdjustment;
                highestTrail = Math.max(highestTrail, trailPrice);
                return {
                    time: bar.time,
                    value: highestTrail,
                };
            });
        
        trailPriceSeries.setData(trailPrices);
        
        if (trade.take_profit_pct != null){
            console.log("bbb")
            const takeProfitSeries = chart.current.addLineSeries({
                color: '#00ff00',
                lineWidth: 2,
            });

            const initialTakeProfitPrice = trade.entry_price * (1 + trade.take_profit_pct);
            const takeProfitPrices = trade.bars
                .filter(bar => bar.time >= startTime)
                .map((bar) => ({
                time: bar.time,
                value: initialTakeProfitPrice,
            }));

            takeProfitSeries.setData(takeProfitPrices);

            takeProfitSeries.setMarkers([
                {
                    time: formatDate(takeProfitPrices[0].time),
                    position: 'aboveBar',
                    color: '#00ff00',
                    shape: 'arrowDown',
                    text: 'Take Profit',
                },
            ])
        }

        const barData = trade.bars;
        if (barData.length > 0) {
            const from = barData[0].time;
            const to = from + 3600; 
            chart.current.timeScale().setVisibleRange({ from, to });
        }

        trailPriceSeries.setMarkers([
            {
                time: formatDate(trailPrices[0].time),
                position: 'aboveBar',
                color: '#ff0000',
                shape: 'arrowDown',
                text: 'Trail Price',
            },
        ])
     
        const addVerticalLine = (time, color) => {
            const lineSeries = chart.current.addHistogramSeries({
                color: color,
                priceFormat: {
                    type: 'volume',
                },
                scaleMargins: {
                    top: 0,
                    bottom: 0,
                },
                priceScaleId: '',
            });
            lineSeries.setData([
                { time: time, value: Number.MAX_VALUE },
            ]);
        };

        addVerticalLine(formatDate(trade.start_time), 'rgba(33, 150, 243, 0.5)');
        addVerticalLine(formatDate(trade.end_time), 'rgba(233, 30, 99, 0.5)');

    }, [trade]);

    return (
        <div className={styles["chart-wrapper"]}>
            <span className={styles["trade-number"]}>{tradeNumber}</span>
            <div className={styles["trade-info"]}>
                <span><strong>Start Date: </strong>{formatDateTime(trade.start_time)}</span>
                <span><strong>End Date: </strong>{formatDateTime(trade.end_time)}</span>
                <span><strong>Entry Price: </strong>${trade.entry_price}</span>
                <span><strong>Pct Return: </strong>{trade.pct_return}</span>
                <span><strong>Duration: </strong>{trade.duration}</span>
                <span><strong>Abs. Profit: </strong>${trade.profit.toFixed(2)}</span>

            </div>
            <div className={styles["chart-container"]} ref={chartContainerRef} />
        </div>
    )
};

export default TradesCandlestick;
