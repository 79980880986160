import React from "react";
import { portfolio } from "../../api/charts/portfolio";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { getSymbols, getBacktestsBySymbol } from "../../api/symbols/symbols";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTable from "../../components/customTable/customTable";
import PortfolioChart from "../../components/portfolio/PortfolioChart";
import styles from "./portfolio.module.css";

function Chart() {
    const [chartData, setChartData] = useState();
    const [symbols, setSymbols] = useState([]);

    const [backtests, setBacktests] = useState([]);
    const [formValues, setFormValues] = useState({
        symbol: null,
        backtest_id: null,
    });

    const [shownBacktest, setShownBacktest] = useState({});

    const validateForm = (values) => {
        if (values.symbol == null || values.symbol === "") {
            toast.error("symbol cannot be empty");
            return false;
        }

        if (values.backtest_id == null || values.backtest_id === "") {
            toast.error("backtest_id cannot be empty");
            return false;
        }

        return true;
    };

    const fetchPortfolioData = async (event) => {
        event.preventDefault();
        const isValid = validateForm(formValues);
        if (!isValid) {
            return;
        }

        let portfolioResponse = await portfolio({
            symbol: formValues.symbol,
            backtest_id: formValues.backtest_id,
        });

        if (!portfolioResponse || !portfolioResponse.data) {
            toast.error("timeout");
            setChartData(null);
            return;
        }

        if (portfolioResponse.data.portfolios.length === 0) {
            toast.error("no data for account portfolio");
            setChartData(null);
            return;
        }

        if (portfolioResponse.status !== 200) {
            toast.error(portfolioResponse?.data?.error);
            setChartData(null);
            return;
        }

        setChartData(portfolioResponse);
    };

    const fetchSymbols = async (symbolBegining) => {
        let data = await getSymbols(symbolBegining);

        if (!data) {
            toast.error("failed to fetch symbols");
            return;
        }

        if (data.status !== 200) {
            toast.error(data?.data?.error);
            return;
        }

        setSymbols(data.data.symbols);
    };

    const handleSymbolInputChange = (event, newInputValue) => {
        if (newInputValue.length < 2) {
            toast.error("symbol must be at least 2 characters long");
            setSymbols([]);
            return;
        }

        newInputValue = newInputValue.toUpperCase();
        fetchSymbols(newInputValue);
    };

    const handleSymbolChange = async (event, newValue) => {
        let data = await getBacktestsBySymbol(newValue);
        if (data.status !== 200) {
            toast.error(data?.data?.error);
            return;
        }

        setFormValues((prevState) => ({
            ...prevState,
            backtest_id: null,
            symbol: newValue,
        }));
        setBacktests(data?.data?.backtests);
    };

    const handleBacktestChange = (event, newValue) => {
        setShownBacktest(backtests.find((backtest) => backtest.backtest_id === newValue));

        setFormValues({
            ...formValues,
            backtest_id: newValue,
        });
    };

    return (
        <div className={styles["candlestick-container"]}>
            <div>
                <form onSubmit={fetchPortfolioData}>
                    <Stack spacing={2}>
                        <h2>Portfolio change chart</h2>
                        <div className={styles["candlestick-form"]}>
                            <Autocomplete
                                className={styles["candlestick-form-input"]}
                                id="symbol-autocomplete"
                                options={symbols}
                                value={formValues.symbol}
                                getOptionLabel={(option) => option}
                                onInputChange={handleSymbolInputChange}
                                onChange={handleSymbolChange}
                                renderInput={(params) => <TextField {...params} label="Symbol" />}
                            />
                            <Autocomplete
                                className={styles["candlestick-form-input"]}
                                id="backtest-autocomplete"
                                options={backtests.map((backtest) => backtest.backtest_id)}
                                value={formValues.backtest_id}
                                getOptionLabel={(option) => option.toString()}
                                onChange={handleBacktestChange}
                                renderInput={(params) => <TextField {...params} label="Backtest id" />}
                            />
                        </div>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Generate chart
                        </Button>
                    </Stack >
                </form >
            </div >

            <div>
                <CustomTable rows={[shownBacktest]}></CustomTable>
            </div>

            {chartData && <PortfolioChart chartData={chartData}></PortfolioChart>}
        </div >
    );
}

export default Chart;
