import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    Box,
} from "@mui/material";
import { fetchSettings, updateSettings } from "../../api/settings/settings";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Settings() {
    const navigate = useNavigate();

    const [settings, setSettings] = useState(null);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        const getSettings = async () => {
            const response = await fetchSettings();
            if (!response) {
                toast.error("no settings");
                return;
            }

            if (response.status !== 200) {
                toast.error(response?.data?.error);
                return;
            }

            setSettings(response.data);
            setTables(response.data.TABLES);
        };

        getSettings();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        // Implement save functionality here
        let res = await updateSettings(settings)
        if (!res) {
            toast.error("failed to update")
            return
        }

        if (res.status !== 200) {
            toast.error(res?.data?.error)
            return
        }

        toast.success("successfully updated")

        navigate("/backtests")
    };

    if (!settings) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Box component="form" noValidate autoComplete="off">
                <TextField
                    label="Database Password"
                    name="DB_PASS"
                    value={settings.DB_PASS}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Database User"
                    name="DB_USER"
                    value={settings.DB_USER}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Database Name"
                    name="DB_NAME"
                    value={settings.DB_NAME}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Database Host"
                    name="DB_HOST"
                    value={settings.DB_HOST}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Database Port"
                    name="DB_PORT"
                    value={settings.DB_PORT}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Backtest Table</InputLabel>
                    <Select
                        label="Backtest Table"
                        name="BACKTEST_TABLE"
                        value={settings.BACKTEST_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Market Table</InputLabel>
                    <Select
                        label="Market Table"
                        name="MARKET_DATA_TABLE"
                        value={settings.MARKET_DATA_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Assets Table</InputLabel>
                    <Select
                        label="Assets Table"
                        name="ASSETS_TABLE"
                        value={settings.ASSETS_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>BT Trades Table</InputLabel>
                    <Select
                        label="BT Trades Table"
                        name="BT_TRADES_TABLE"
                        value={settings.BT_TRADES_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Orders table</InputLabel>
                    <Select
                        label="Orders table"
                        name="ORDERS_TABLE"
                        value={settings.ORDERS_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Portfolios table</InputLabel>
                    <Select
                        label="Portfolios table"
                        name="ORDERS_TABLE"
                        value={settings.PORTFOLIO_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Portfolio values table</InputLabel>
                    <Select
                        label="Portfolios values table"
                        name="PORTFOLIO_VALUES_TABLE"
                        value={settings.PORTFOLIO_VALUES_TABLE}
                        onChange={handleChange}
                    >
                        {tables.map((table) => (
                            <MenuItem key={table} value={table}>
                                {table}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </Container >
    );
}

export default Settings;
